<template>
  <div class="nav-bar" :class="{'pageNum1' : pageNum.pageNum == 1 && activePath == '/home','pageNum2' : pageNum.pageNum == 2 && activePath == '/home' && !showLogin}">
    <div class="center-content">
      <div class="menu">
        <div class="menu-ul">
          <template v-for="(item, index) in routeList">
            <!-- 单行 -->
            <div class="menu-item" v-if="!item.hidden && !item.childs" @click="handleSelect(item)" :index="index" :class="(item.path === activePath || (item.path === '/' && activePath === '/home')) ? 'is-active' : ''">
              <span class="tracking-in-expand">{{item.meta.title}}</span>
            </div>
            <div class="menu-item select" v-if="!item.hidden && item.childs" :index="index" :class="(item.path === activePath || (item.path === '/' && activePath === '/home')) ? 'is-active' : ''">
              <span class="tracking-in-expand">{{item.meta.title}}</span>
              <div class="hh">
                <div class="sanjiao"></div>
                <div class="nav-box">
                  <div class="nav-child" v-for="nav in item.children" @click="handleSelect(item,nav)" v-if="!nav.hidden">{{nav.title}}</div>
                </div>
              </div>
            </div>
            <!-- 多行 -->
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
export default {
  name: 'Navbar',
  data() {
    return {
      routeList: [],
      activePath: '/'
    }
  },
  computed: {
    ...mapGetters([
      'pageNum',
      'showLogin'
    ])
  },
  watch: {
    $route(to, form) {
      this.handleSetRoute()
    }
  },
  mounted() {
    this.handleSetRoute()
  },
  methods: {
    handleSelect(item, nav) {
      if (item.path === '/personal') {
        if (getToken()) {
          this.$router.push({
            path: item.path
          })
        } else {
          this.$store.dispatch('setActiveTab', 'login')
          this.$store.dispatch('setShowLogin', true)
        }
      } else {
        if (nav) {
          this.$router.push({
            path: item.path + '/' + nav.path
          })
        } else {
          this.$router.push({
            path: item.path
          })
        }
      }
    },
    handleSetRoute() {
      const _hash = window.location.hash.split('#')[1].split('?')[0]
      const _arr = this.$router.options.routes
      const _hideArr = ['/train/index', '/train/detail', '/train/course', '/train/courseDetail', '/personal/train', '/trainShoppingCart/payOrder', '/welcome/home', '/welcome', '/authorize', '/learnCentre', '/setFlag', '/goLogin', '/loginRedirect/home']
      if (_hideArr.indexOf(_hash) > -1) {
        for (const item of _arr) {
          if (item.name === 'train' || (item.name === 'personal')) {
            item.hidden = false
          } else {
            item.hidden = true
          }
        }
      } else {
        for (const item of _arr) {
          if (item.name === 'personal' || item.name === 'shoppingCart' || item.name === 'task' || item.name === 'feedback' || item.name === 'exam' || item.name === 'search' || item.name === 'trainShoppingCart' || item.name === 'welcome' || item.name === 'authorize' || item.name === 'learnCentre' || item.name === 'setFlag' || item.name === 'goLogin' || item.name === 'loginRedirect' || item.name === 'lndxCourse' || (item.name === 'campus') || (item.name === 'study') || (item.name === 'education')) {
            item.hidden = true
          } else {
            item.hidden = false
          }
        }
      }
      this.routeList = _arr
      for (const item of this.routeList) {
        const _path = item.path === '/' ? '/home' : item.path
        if (_hash.indexOf(_path) > -1) {
          this.activePath = _path
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-bar{
  width: 100%;
  height: 82px;
  z-index: 2;
  min-width: 1440px;
  background-color: #aa1e00;
  padding: 0 60px;
  &.pageNum1{
    position: absolute;
    z-index: 1;
    background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0));
    .center-content{
      background-color: transparent;
    }
  }
  &.pageNum2{
    position: fixed;
    top: 108px;
  }
  .center-content{
    width: 100%;
    height: 82px;
    margin: 0 auto;
    .menu{
      .menu-ul{
        display: flex;
        justify-content: center;
        .menu-item{
          display: inline-block;
          padding: 0;
          margin-right: 50px;
          height: 74px;
          line-height: 74px;
          margin-top: 4px;
          font-size: 24px;
          color: #FFFFFF;
          position: relative;
          cursor: pointer;
          &:last-child{
            margin-right: 0;
          }
          &:hover{
            color: #FFE073;
          }
          &.is-active{
            font-weight: 500;
            border: none;
            color: #FFE073;
            border-bottom: 7px solid #FFE073;
          }
        }
      }
    }
  }
  .select{
    position: relative;
    .hh{
      position: absolute;
      display: none;
      width: 100%;
      z-index:999999;
      text-align: center;
      top: 68px;
      .sanjiao{
        border-top: 10px solid transparent;
        border-bottom: 10px solid rgba(0,0,0,.7);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        margin-left: 64px;
        width: 10px;
      }
      .nav-box{
        width: 100%;
        background-color: rgba(0,0,0,.7);
        padding: 10px 0;
      }
      .nav-child{
        line-height: 45px;
        width: 100%;
        font-size: 26px;
        color: #fff;
        &:hover{
          color: #FFE073;
        }
      }
    }
    &:hover{
      .hh{
        display: block;
      }
    }
  }
}
.tracking-in-expand {
  -webkit-animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

</style>
